.lander-container {
    min-height: 100vh;
    background-color: #000;
    color: #fff;
    padding: 2rem;
    padding-top: 80px;
  }
  
  .lander-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4rem;
  }
  
  .lander-text {
    flex: 1;
  }
  
  .heading-dot {
    color: #dc004e;
  }
  
  .lander-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .lander-image img {
    max-width: 100%;
    height: auto;
  }
  
  @media (max-width: 900px) {
    .lander-content {
      flex-direction: column;
      text-align: center;
    }
  
    .feature-list {
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
  }