.features .box {
    box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
    border-radius: 6px;
    text-align: center;
    padding: 30px;
    cursor: pointer;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .features img {
    width: 65px;
    height: 65px;
    margin: auto;
  }
  .subsection {
    max-width: 80%;
    margin: auto;
  }
  
  .backgrounds {
    padding: 80px 0;
    position: relative;
    background: rgb(16, 21, 34);
  }
  .headings {
    text-align: center;
    margin: auto;
  }
  .headings h1 {
    color: #fff;
    font-weight: 600;
    font-size: 48px;
  }
  .headings p {
    color: #72809d;
  }
  .grid5 {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 30px;
  }
  .mtop {
    margin-top: 50px;
  }
  
  @media screen and (max-width: 800px) {
  
    .grid5 {
      grid-template-columns: repeat(2, 1fr);
    }
    .heading {
      width: 100%;
    }
    .container {
      max-width: 90%;
    }
  }