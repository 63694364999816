.avatar-container {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
  }
  
  .avatar-image {
    width: 186px;
    height: 186px;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .overlay-text {
    position: absolute;
    bottom: 10px; /* You can adjust the positioning as needed */
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 12px;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 5px;
    border-radius: 5px;
  }
  