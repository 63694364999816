/* Contact Page */
.contact-inner-wrapper {
  padding: 30px 20px;
  border-radius: 15px;
border: 1px solid black;
  gap: 15px;
  display: flex;
  justify-content: space-between;
}
.contact-inner-wrapper > div {
  width: 48%;
}
.contact-title {
  font-size: 26px;
  font-weight: 500;
  line-height: 35px;
  text-align: left;
  margin-bottom: 4rem;
}
.contact-inner-wrapper form .form-control {
  background-color: var(--color-f5f5f7);
  border-radius: 10px;
  border-color: transparent;
  padding: 10px;
}
.contact-inner-wrapper ul {
  list-style: none;
}
.contact-inner-wrapper address,
.contact-inner-wrapper a,
.contact-inner-wrapper p {
  color: var(--color-777777);
}


.from-container h1{
  display: block;
margin: auto;
text-align: center;
}

.from-container{
  margin: 2rem 6rem;
  color: #2a2a2a;
}

.from-container form{
  padding: 3rem;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 25rem;
}

.from-container input{
  height: 3rem;
  padding: 0 1rem;
  margin-bottom: 2rem;
  border-radius: .3rem;
  width: 25rem;

}

.from-container textarea{
    padding: 12px;
    font-size: 14px;
    border: 1px solid #ccc;
    box-shadow: 0 1px 4px #ccc;
    border-radius: 6px;
    font-size: 18px;
    width: 25rem;
    margin-bottom: 2rem;


}
.from-container button{

display: inline;
width: 25rem;

}

@media  screen and (max-width:850px) {
  .from-container h1{
  text-align: center;

  
  }
  .from-container{
    margin: 4rem 2rem;
  }
  
  .from-container form{
    padding: 2rem;
    display: block;
    margin: auto;
  }
  .from-container input{
    width: 15rem;
}
  .from-container textarea{
    width: 15rem;
    
}

.from-container button{
  width: 15rem;
}
}

.one {
  margin-bottom:3rem;
  display:flex;
  gap:15px;
  align-items:center;
}