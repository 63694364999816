.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  margin-bottom: 20px;
}

.loading-text {
  color: navy;
  font-size: 18px;
}

.dot {
  animation: bounce 1.4s infinite;
  display: inline-block;
  opacity: 0;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%, 80%, 100% { 
    transform: translateY(0);
    opacity: 0;
  }
  30% { 
    transform: translateY(-5px);
    opacity: 1;
  }
}
